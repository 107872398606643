<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-10">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Saida Produtos e Insumos</h3>
        </div>
        <div class="card-body">
          <div class="row form-group">
            <div class="form-group col-md-3">
              <label class="col-md-12">{{ $t("DATAINICIO.NOME") }}</label>
              <input
                required
                type="date"
                class="form-control"
                v-model="filtro.data_inicio"
              />
            </div>
            <div class="form-group col-md-3">
              <label class="col-md-12">{{ $t("DATAFINAL.NOME") }}</label>
              <input
                required
                type="date"
                class="form-control"
                v-model="filtro.data_fim"
              />
            </div>
            <div class="form-group col-md-4">
              <label class="col-md-12">Seleccionar el producto :</label>
              <treeselect
                v-model="filtro.produto_id"
                :multiple="false"
                :options="lista_produtos"
              />
            </div>
            <div class="col-md-2 justify-content-end row mt-8">
              <div class="ml-1">
                <button
                  class="btn btn-success"
                  @click="listar_saida_produtos()"
                >
                  <i class="fa fa-search search-icon m-0 p-0"></i>
                </button>
              </div>
              <div class="ml-1">
                <button class="btn btn-info" @click="gerarExcel()">
                  <i class="fa fa-print m-0 p-0"></i>
                </button>
              </div>
            </div>
          </div>
          <b-table
            :fields="[
              'data',
              'paciente',
              'produto',
              'quantidade',
              'codigo_barra',
              'valor',
              'funcionario',
              'ocorrencia',
              'descricao',
              'lote',
              // 'acoes',
            ]"
            :items="lista_saida_produtos"
            :per-page="perPage"
            :current-page="currentPage"
            id="saida_produto"
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
              table-responsive
            "
            show-empty
            empty-text="Ningún registro encontrado!"
          >
            <!-- <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }"> -->
            <!-- <div class="text-right w-100">
                <button
                  v-show="lista_permissoes_filial.u_Sala"
                  @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                  v-show="lista_permissoes_filial.lock_Sala"
                  @click="confirm('desfazer a saída')"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Desfazer saida"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
              </div>
            </template> -->
            <template #cell(ocorrencia)="{ item }">
              <div class="text-right w-100">
                <span
                  class="label label-lg label-inline"
                  :class="{
                    'label-light-info': item.ocorrencia === 1,
                    'label-light-success': item.ocorrencia === 2,
                    'label-light-warning': item.ocorrencia === 3,
                    'label-light-primary': item.ocorrencia === 4,
                  }"
                >
                  {{
                    item.ocorrencia === 1
                      ? "Triagem"
                      : item.ocorrencia === 2
                      ? "Consulta"
                      : item.ocorrencia === 3
                      ? "Internação"
                      : item.ocorrencia === 4
                      ? "Receita"
                      : "Doação"
                  }}
                </span>
              </div>
            </template>
            <template #cell(valor)="{ item }">
              <money-format
                :value="item.valor"
                :locale="es"
                :currency-code="PYG"
                :subunits-value="false"
                :hide-subunits="false"
              >
              </money-format>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_saida_produtos.length"
            :per-page="perPage"
            aria-controls="saida_produto"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoneyFormat from "vue-money-format";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  components: { "money-format": MoneyFormat },
  mixins: { fireAlert },
  data() {
    return {
      cost: 12345,
      currentPage: 1,
      perPage: 20,
      filtro: {
        data_inicio: null,
        data_fim: null,
        produto_id: null,
        tipo_saida_id: null,
        estoque_produto_id: null,
        filial_id: null,
        tipo:'local'
      },
   
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Relatorio" }]);
  },
  created() {
    this.listar_saida_produtos();
  },
  computed: {
    lista_saida_produtos() {
      return this.$store.state.produto.lista_saida_produtos;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
    lista_produtos() {
      return this.$store.state.produto.lista_produtos;
    },
  },
  methods: {
    async listar_saida_produtos() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("produto/listar_saida_produtos", this.filtro)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("produto/listar_produto", "");

          // localStorage.removeItem('produto');
        });
    },
    async gerarExcel() {
      await this.$store.dispatch("relatorios/excelSaidas", this.filtro);
    },

    atualizar(value) {
      this.$router.push({
        name: "createSaidaProduto",
        params: { saida_produto_id: value.id },
      });
      // this.$store.dispatch("configEmpresa/atualizar", value);
      // localStorage.setItem('produto',JSON.stringify(value));
      // this.$store.dispatch("produto/listar_produto", value.id);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + value,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (value == "desfazer a saída") this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("produto/bloquear_produto", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>